<template>
  <b-form @submit.prevent="save">
    <b-row class="mt-1" align-v="center">
      <b-col md="3" v-if="!isEdition">
        <ValidationProvider name="centro de consumo" rules="required">
          <b-form-group label="Centro de consumo*" slot-scope="{ valid, errors }">
            <b-form-select
              :state="errors[0] ? false : valid ? true : null"
              v-model="guest.consumptionCenter"
              :disabled="isLoadingGuests"
              class="font-weight-bolder"
            >
              <option :value="null" disabled>Seleccione una opción</option>
              <option v-for="c in consumptionCenters" :key="c.id" :value="c.id">
                {{ c.name }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col md="3">
        <b-row align-v="center">
          <b-col md="">
            <ValidationProvider rules="required" name="habitación">
              <b-form-group label="Habitación*" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  v-model="guest.room"
                  @keypress="onlyNumbersLetters"
                  :disabled="isLoadingGuests"
                  placeholder="N° Habitación"
                  :state="errors[0] || !valid ? false : null"
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3" class="mt-1 pl-0 text-right" v-if="consumptionCenter && !!consumptionCenter.hotelSihot">
            <b-button @click="searchGuestByRoom" :disabled="!guest.room || isLoadingGuests" variant="primary">
              <b-spinner v-if="isLoadingGuests" small/> <feather-icon v-else icon="SearchIcon"/>
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="3" v-if="guests.length">
        <ValidationProvider name="Huésped" rules="">
          <b-form-group label="Huésped*" slot-scope="{ valid, errors }">
            <b-form-select
              :state="errors[0] ? false : valid ? true : null"
              v-model="selectedGuest"
              @input="setCustomer(selectedGuest)"
            >
              <option :value="null">Seleccione Huésped</option>
              <option v-for="(guest, index) in guests" :key="index" :value="guest">
                {{ guest.name }} {{ guest.lastname }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
    <hr>

    <b-overlay :show="isLoadingGuestInfo">
      <b-row>
        <b-col md="3">
          <ValidationProvider rules="required" name="nombre">
            <b-form-group label="Nombre*" slot-scope="{ valid, errors }">
              <b-form-input
                class="form-control"
                type="text"
                v-model="guest.name"
                placeholder="Nombre del cliente"
                :state="errors[0] ? false : valid ? true : null"
              >
              </b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="required" name="apellido">
            <b-form-group label="Apellido*" slot-scope="{ valid, errors }">
              <b-form-input
                class="form-control"
                type="text"
                v-model="guest.lastname"
                placeholder="Apellido del cliente"
                :state="errors[0] ? false : valid ? true : null"
              >
              </b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider name="idioma" rules="required">
            <b-form-group label="Idioma*" slot-scope="{ valid, errors }">
              <b-form-select
                :state="errors[0] ? false : valid ? true : null"
                v-model="guest.language"
              >
                <option :value="null" disabled>Seleccione Idioma</option>
                <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                  {{ lang.name }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="required|min_value:1" name="número de pax">
            <b-form-group label="Número de pax*" slot-scope="{ valid, errors }">
              <b-form-input
                class="form-control"
                type="number"
                step="1"
                v-model="guest.pax"
                @keypress="onlyNumber"
                placeholder=""
                :state="errors[0] ? false : valid ? true : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="" name="Código de pais">
            <b-form-group label="Código de pais*" slot-scope="{ valid, errors }">

              <v-select
                v-model="guest.lada"
                label="label"
                :options="countryCodes"
                :filter="customFilterLada"
                :class="errors[0] ? 'is-invalid' : valid ? 'is-valid' : null"
              />

              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <!-- required|min:10 -->
          <ValidationProvider name="teléfono" rules="" vid="phone">
            <b-form-group label="Teléfono*" slot-scope="{ valid, errors }">
              <b-form-input
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="20"
                type="text"
                autocomplete="off"
                v-model="guest.phone"
                :class="errors[0] ? 'is-invalid' : valid ? 'is-valid' : null"
                placeholder="Ingrese número de teléfono"
                @keypress="formatPhoneNumber"
              />
              <b-form-invalid-feedback>
                {{ errors[0] || (!!guest.phone && guest.phone.length < 10 ? 'El campo teléfono debe tener al menos 10 caracteres' : '')}}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="" name="Notas">
            <b-form-group label="Notas" slot-scope="{ valid, errors }">
              <b-form-textarea
                v-model="guest.notes"
                placeholder="Inserte notas aquí (opcional)"
                :state="errors[0] ? false : valid ? true : null"
                rows="4"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="float-right">
            <b-button
              type="submit"
              class="btn-block"
              variant="primary"
              :disabled="invalid || isSavingCustomer"
            >
              <b-spinner small v-if="isSavingCustomer" /> Guardar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-overlay>

  </b-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select';
import { utils } from "@/modules/fivesClub/mixins/utils";
import { market } from '@/modules/shop/mixins/market';

export default {
  mixins: [utils, market],
  components:{
    vSelect,
  },
  props: {
    guest:{
      type: Object,
      required: true,
    },
    invalid: {
      type: Boolean,
    },
    isSavingCustomer: {
      type: Boolean,
    },
    isEdition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoadingGuestInfo: false,
      isLoadingGuests: false,
      block: {
        name: false,
        lastname: false,
        lada: false,
        phone: false,
        language: false
      },
      guests: [],
      selectedGuest: null
    }
  },
  watch:{
    consumptionCenter:{
      deep: true,
      handler(data, oldData){
        if (data?.hotelSihot != oldData?.hotelSihot){
          this.clearGuestsData()
        }
      }
    }
  },
  computed:{
    ...mapState('guestPager', ['countryCodes', 'consumptionCenters']),
    ...mapState('start', ['languages']),
    consumptionCenter(){
      const hotel = this.consumptionCenters?.find(cc => cc.id == this.guest?.consumptionCenter)
      return hotel || null
    }
  },
  methods: {
    ...mapActions('guestPager', ['fetchGuestByRoom', 'fetchGuestData']),
    save(){
      this.$emit('save-changes', this.guest)
    },
    customFilterLada(options, search){
      const searchStr = search.toLowerCase()
      return options.filter(opt =>
        opt.code.toLowerCase().includes(searchStr) ||
        opt.country.toLowerCase().includes(searchStr) ||
        opt.code.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchStr) || // normaliza el texto y remueve acentos
        opt.country.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchStr) // normaliza el texto y remueve acentos
      )
    },
    async setCustomer(customer){
      if (!!customer){
        this.isLoadingGuestInfo = true
        const guestData = await this.fetchGuestData({guestId: customer.id, hotel: this.consumptionCenter?.hotelSihot})
        if (guestData) {
          this.guest.name = guestData.name
          this.guest.lastname = guestData.lastname
          this.guest.phone = guestData.phone
          this.guest.language = guestData.language
          if (guestData.country) {
            const country = this.countryCodes.find(country => country.id==guestData.country)
            this.guest.lada = country
          } else this.guest.lada = null
        }
        this.isLoadingGuestInfo = false
      } else this.clearGuestInfo()
    },
    async searchGuestByRoom(){
      this.isLoadingGuests = true
      this.clearGuestsData()
      this.guests = await this.fetchGuestByRoom({room: this.guest.room, hotel: this.consumptionCenter?.hotelSihot})
      this.isLoadingGuests = false
    },
    clearGuestInfo(){
      this.guest.name = null
      this.guest.lastname = null
      this.guest.lada = null
      this.guest.phone = null
      this.guest.language = null
    },
    clearGuestsData(){
      this.selectedGuest = null
      this.guests = []
      this.guest.name = null
      this.guest.lastname = null
      this.guest.lada = null
      this.guest.phone = null
      this.guest.language = null
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
